import React, { useEffect, useState } from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { RiTodoLine } from 'react-icons/ri'
import { connect } from 'react-redux'

import styles from './ToDoPage.module.scss'
import Confirm from '../../../../components/Confirm/Confirm'
import IconButton from '../../../../components/IconButton/IconButton'
import {
  deleteToDo,
  filterToDoes,
  getToDoes,
  sortToDoes,
} from '../store/toDo.slice'
import { RootState } from '../../../../redux/store'
import { ToDo as ToDoType } from '../types/toDo'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import ToDoDetails from '../components/ToDoDetails/ToDoDetails'
import ToDo from '../components/ToDo/ToDo'
import MyLoader from '../../../../components/MyLoader/MyLoader'
import FormAddNote from '../components/FormAddNote/FormAddNote'
import { sortToDoesByPriority } from '../utils/sortDodoesByPriority'

interface ToDoPageProps {
  toDoes: ToDoType[]
  getToDoes: () => void
  deleteToDo: (id: number) => void
  sortToDoes: (priority: string) => void
  filterToDoes: (type: string) => void
}

const ToDoPage = (props: ToDoPageProps) => {
  const { toDoes, getToDoes, deleteToDo, sortToDoes, filterToDoes } = props

  const [selectedPriority, setSelectedPriority] = useState<string>('Default')
  const [isActiveAddToDoeTable, setIsActiveAddToDoeTable] =
    useState<boolean>(false)
  const [selectedNoteId, setSelectedNoteId] = useState<number>(0)
  const [selectedToDo, setSelectedToDo] = useState<ToDoType | undefined>()
  const [isActiveEditMode, setIsActiveEditMode] = useState<boolean>(false)
  const [toDoDetails, setToDoDetails] = useState<ToDoType | undefined>()
  const [isActiveConfirmDelete, setIsActiveConfirmDelete] =
    useState<boolean>(false)
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)
  const [activeToDoes, setActiveToDoes] = useState<ToDoType[]>([])
  const [pausedToDoes, setPausedToDoes] = useState<ToDoType[]>([])
  const [finishedToDoes, satFinishedToDoes] = useState<ToDoType[]>([])
  const [selectedTab, setSelectedTab] = useState<string>('Active')

  useEffect(() => {
    getToDoes()
    const screenWidth = window.innerWidth
    screenWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (toDoes?.length > 0) {
      setActiveToDoes(
        sortToDoesByPriority(
          toDoes.filter((toDo) => toDo.status === 'Active'),
          'High',
        ),
      )
      setPausedToDoes(
        sortToDoesByPriority(
          toDoes.filter(
            (toDo) =>
              toDo.status === 'Paused' ||
              toDo.status === 'Passive' ||
              !toDo.status,
          ),
          'High',
        ),
      )
      satFinishedToDoes(
        sortToDoesByPriority(
          toDoes.filter((toDo) => toDo.status === 'Finished'),
          'High',
        ),
      )
    }
  }, [toDoes])

  useEffect(() => {
    if (selectedPriority) {
      sortToDoes(selectedPriority)
    }
  }, [selectedPriority])

  const onOpenAddToDoeTable = () => {
    setIsActiveAddToDoeTable(true)
  }

  const onClickCancel = () => {
    setIsActiveAddToDoeTable(false)
  }

  const onEditToDoe = (note: ToDoType) => {
    setSelectedToDo(note)
    setIsActiveEditMode(true)
  }

  const onOpenToDoeDeleteAlert = (note: ToDoType) => {
    setSelectedToDo(note)
    setIsActiveConfirmDelete(true)
  }

  const onDeleteToDoe = () => {
    deleteToDo(selectedToDo!.id)
    setIsActiveConfirmDelete(false)
  }

  return (
    <div className={styles.root}>
      <div className={styles.notesTop}>
        {/* <Dropdown
          onSelect={(priority: any) =>
            priority && setSelectedPriority(priority)
          }
        >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Sort by priority
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={'Default'}>Default</Dropdown.Item>
            <Dropdown.Item eventKey={'Low'}>Low</Dropdown.Item>
            <Dropdown.Item eventKey={'Medium'}>Medium</Dropdown.Item>
            <Dropdown.Item eventKey={'High'}>High</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <div title={'Create New ToDo'} className={styles.addToDo}>
          <IconButton onClick={onOpenAddToDoeTable}>
            <RiTodoLine className={styles.icon} size={30} />
          </IconButton>
        </div>
      </div>
      <div className={styles.toDoes}>
        {toDoes ? (
          <>
            {isSmallScreen ? (
              <div className={styles.smallScreenColumnsContainer}>
                <div className={styles.smallScreenTabs}>
                  <Nav
                    variant="tabs"
                    defaultActiveKey="Active"
                    activeKey={selectedTab}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Paused"
                        onClick={() => setSelectedTab('Paused')}
                      >
                        Paused
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Active"
                        onClick={() => setSelectedTab('Active')}
                      >
                        Active
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Finished"
                        onClick={() => setSelectedTab('Finished')}
                      >
                        Finished
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className={styles.smallScreenColumn}>
                  {selectedTab === 'Active' ? (
                    <div className={styles.items}>
                      {activeToDoes.length > 0 ? (
                        activeToDoes.map((toDo, index) => (
                          <ToDo
                            key={`${toDo.id} - ${index}-todo`}
                            toDo={{
                              ...toDo,
                              description: convertHtmlToNode(
                                toDo.description as string,
                              ),
                            }}
                            selectedNoteId={selectedNoteId}
                            setSelectedNoteId={setSelectedNoteId}
                            onEdit={() => onEditToDoe(toDo)}
                            onDetails={() => setToDoDetails(toDo)}
                            onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  ) : selectedTab === 'Paused' ? (
                    <div className={styles.items}>
                      {pausedToDoes.length > 0 ? (
                        pausedToDoes.map((toDo, index) => (
                          <ToDo
                            key={`${toDo.id} - ${index}-todo`}
                            toDo={{
                              ...toDo,
                              description: convertHtmlToNode(
                                toDo.description as string,
                              ),
                            }}
                            selectedNoteId={selectedNoteId}
                            setSelectedNoteId={setSelectedNoteId}
                            onEdit={() => onEditToDoe(toDo)}
                            onDetails={() => setToDoDetails(toDo)}
                            onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  ) : (
                    <div className={styles.items}>
                      {finishedToDoes.length > 0 ? (
                        finishedToDoes.map((toDo, index) => (
                          <ToDo
                            key={`${toDo.id} - ${index}-todo`}
                            toDo={{
                              ...toDo,
                              description: convertHtmlToNode(
                                toDo.description as string,
                              ),
                            }}
                            selectedNoteId={selectedNoteId}
                            setSelectedNoteId={setSelectedNoteId}
                            onEdit={() => onEditToDoe(toDo)}
                            onDetails={() => setToDoDetails(toDo)}
                            onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.columnsContainer}>
                <div className={`${styles.column} ${styles.pausedToDoe}`}>
                  <div className={styles.columnTitle}>Paused</div>
                  <div className={`${styles.items} ${styles.pausedToDoe}`}>
                    {pausedToDoes.length > 0 ? (
                      pausedToDoes.map((toDo, index) => (
                        <ToDo
                          key={`${toDo.id} - ${index}-todo`}
                          toDo={{
                            ...toDo,
                            description: convertHtmlToNode(
                              toDo.description as string,
                            ),
                          }}
                          selectedNoteId={selectedNoteId}
                          setSelectedNoteId={setSelectedNoteId}
                          onEdit={() => onEditToDoe(toDo)}
                          onDetails={() => setToDoDetails(toDo)}
                          onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
                <div className={`${styles.column} ${styles.activeToDoe}`}>
                  <div className={styles.columnTitle}>Active</div>
                  <div className={`${styles.items} ${styles.activeToDoe}`}>
                    {activeToDoes.length > 0 ? (
                      activeToDoes.map((toDo, index) => (
                        <ToDo
                          key={`${toDo.id} - ${index}-todo`}
                          toDo={{
                            ...toDo,
                            description: convertHtmlToNode(
                              toDo.description as string,
                            ),
                          }}
                          selectedNoteId={selectedNoteId}
                          setSelectedNoteId={setSelectedNoteId}
                          onEdit={() => onEditToDoe(toDo)}
                          onDetails={() => setToDoDetails(toDo)}
                          onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
                <div className={`${styles.column} ${styles.finishedToDoe}`}>
                  <div className={styles.columnTitle}>Finished</div>
                  <div className={`${styles.items} ${styles.finishedToDoe}`}>
                    {finishedToDoes.length > 0 ? (
                      finishedToDoes.map((toDo, index) => (
                        <ToDo
                          key={`${toDo.id} - ${index}-todo`}
                          toDo={{
                            ...toDo,
                            description: convertHtmlToNode(
                              toDo.description as string,
                            ),
                          }}
                          selectedNoteId={selectedNoteId}
                          setSelectedNoteId={setSelectedNoteId}
                          onEdit={() => onEditToDoe(toDo)}
                          onDetails={() => setToDoDetails(toDo)}
                          onDelete={() => onOpenToDoeDeleteAlert(toDo)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <MyLoader />
        )}
        {/* {toDoes &&
          toDoes.map((toDo, index) => (
            <ToDo
              key={`${toDo.id} - ${index}-todo`}
              toDo={{
                ...toDo,
                description: convertHtmlToNode(toDo.description as string),
              }}
              selectedNoteId={selectedNoteId}
              setSelectedNoteId={setSelectedNoteId}
              onEdit={() => onEditToDoe(toDo)}
              onDetails={() => setToDoDetails(toDo)}
              onDelete={() => onOpenToDoeDeleteAlert(toDo)}
            />
          ))} */}
      </div>

      <RightDrawer
        title={'Add ToDo'}
        size="l"
        show={isActiveAddToDoeTable}
        onHide={onClickCancel}
      >
        <FormAddNote onClickCancel={onClickCancel} />
      </RightDrawer>

      <RightDrawer
        title={'Edit ToDo'}
        size="l"
        show={isActiveEditMode}
        onHide={() => setIsActiveEditMode(false)}
      >
        {isActiveEditMode && selectedToDo && (
          <FormAddNote
            note={{
              ...selectedToDo,
              description: convertHtmlToNode(
                selectedToDo!.description as string,
              ),
            }}
            onClickCancel={() => setIsActiveEditMode(false)}
          />
        )}
      </RightDrawer>

      <RightDrawer
        title={`${toDoDetails?.name}`}
        size="l"
        show={!!toDoDetails}
        onHide={() => setToDoDetails(undefined)}
      >
        {toDoDetails && <ToDoDetails toDo={toDoDetails} />}
      </RightDrawer>

      {isActiveConfirmDelete && (
        <Confirm
          confirm="Are you really want to delete this ToDo?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteToDoe}
          onCancel={() => setIsActiveConfirmDelete(false)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  toDoes: state.toDo.toDoes,
})

export default connect(mapStateToProps, {
  getToDoes,
  deleteToDo,
  sortToDoes,
  filterToDoes,
})(ToDoPage)
