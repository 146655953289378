import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../redux/store'
import styles from './HomePage.module.scss'
import UpcomingEvents from '../../Events/components/UpcomingEvents/UpcomingEvents'
import ActiveToDoesList from '../../Notes/ToDoes/components/ActiveToDoPage/ActiveToDoesList'
import { getEvents } from '../../Events/store/event.slice'

interface HomePageProps {
  getEvents: () => void
}

const HomePage = (props: HomePageProps) => {
  const { getEvents } = props

  useEffect(() => {
    getEvents()
  }, [])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.block}>
          <h5>Active ToDoes</h5>
          <ActiveToDoesList />
        </div>
        <div className={styles.block}>
          <h5>Upcoming Events (30 days)</h5>
          <UpcomingEvents />
        </div>
      </div>
      {/* <div className={styles.row}>
        <div className={styles.block}>1</div>
        <div className={styles.block}>2</div>
      </div> */}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  getEvents,
})(HomePage)
