import { addDays, format, isAfter, isBefore, parseISO } from 'date-fns'
import { Event } from '../types/event'

export const getUpcomingEvents = (
  events: Event[],
  daysCount: number,
): Event[] => {
  const now = new Date()
  const todayMonthDay = format(now, 'MM-dd')
  const endDate = addDays(now, daysCount)
  const endMonthDay = format(endDate, 'MM-dd')

  const upcomingEvents = events.filter((event) => {
    if (event.type === 'Birthday' || event.type === 'Death') {
      //@ts-ignore
      const eventDate = parseISO(event.dates[0])
      const eventMonthDay = format(eventDate, 'MM-dd')
      if (todayMonthDay <= endMonthDay) {
        // Filter events within the same calendar year range
        return eventMonthDay >= todayMonthDay && eventMonthDay <= endMonthDay
      } else {
        // Handle year boundary (e.g., Dec 30 to Jan 3)
        return eventMonthDay >= todayMonthDay || eventMonthDay <= endMonthDay
      }
    } else {
      //@ts-ignore
      const eventDate = parseISO(event.dates[0])
      return isAfter(eventDate, now) && isBefore(eventDate, endDate)
    }
  })

  return upcomingEvents.reverse()
}
