import {
  CreateObjective,
  CreateStep,
  Objective,
  Step,
} from '../types/objective'
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  loadObjective,
  loadObjectives,
  modifyObjective,
  removeObjective,
  saveObjective,
} from '../api/objective'
import { modifyStep, removeStep, saveStep } from '../api/step'
import { sortStepsByStatus } from '../utils/stepSort'
import { sortObjectivesByDefault } from '../utils/objectivesSorts'

// get all objectives
export const getObjectives = createAsyncThunk(
  'objective/getObjectives',
  async (_, thunkApi) => {
    try {
      return await loadObjectives()
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveError')
    }
  },
)

//get objective
export const getObjective = createAsyncThunk(
  'objective/getObjective',
  async (objectiveId: number, thunkApi) => {
    try {
      return await loadObjective(objectiveId)
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveError')
    }
  },
)

export const refreshObjective = createAsyncThunk(
  'objective/refreshObjective',
  async (objectiveId: number, thunkApi) => {
    try {
      return await loadObjective(objectiveId)
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveError')
    }
  },
)

// add objective
export const addObjective = createAsyncThunk(
  'objective/addObjective',
  async (objective: CreateObjective, thunkApi) => {
    try {
      return await saveObjective(objective)
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveAddError')
    }
  },
)

// update objective status
export const updateObjective = createAsyncThunk(
  'objective/updateObjective',
  async (objective: Objective, thunkApi) => {
    try {
      return await modifyObjective(objective)
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveError')
    }
  },
)

// delete objective
export const deleteObjective = createAsyncThunk(
  'objective/deleteObjective',
  async (id: number, thunkApi) => {
    try {
      return await removeObjective(id)
    } catch (e) {
      return thunkApi.rejectWithValue('objectiveError')
    }
  },
)

//save step
export const addStep = createAsyncThunk(
  'step/saveStep',
  async (step: CreateStep, thunkApi) => {
    try {
      return await saveStep(step)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//update step
export const updateStep = createAsyncThunk(
  'step/updateStep',
  async (step: Step, thunkApi) => {
    try {
      const updatedStep = await modifyStep(step)
      return updatedStep
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//delete step
export const deleteStep = createAsyncThunk(
  'step/deleteStep',
  async (stepId: number, thunkApi) => {
    try {
      const id = await removeStep(stepId)
      return id
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface ObjectiveSliceState {
  objectives: Objective[]
  objective?: Objective
  objectiveDetails?: Objective
  objectiveLoading: boolean
  objectiveError?: SerializedError
  stepLoading: boolean
  stepError?: SerializedError
}

const initialState: ObjectiveSliceState = {
  objectives: [],
  objective: undefined,
  objectiveDetails: undefined,
  objectiveLoading: false,
  objectiveError: undefined,
  stepLoading: false,
  stepError: undefined,
}

const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    setObjectives: (state, action: PayloadAction<Objective[]>) => {
      //@ts-ignore
      state.objectives = action.payload
    },

    setObjective: (state, action: PayloadAction<Objective | undefined>) => {
      //@ts-ignore
      state.objective = action.payload
    },

    setObjectiveDetails: (
      state,
      action: PayloadAction<Objective | undefined>,
    ) => {
      //@ts-ignore
      state.objectiveDetails = action.payload
    },
  },
  extraReducers: {
    // get objectives
    [getObjectives.fulfilled.type]: (
      state,
      action: PayloadAction<Objective[]>,
    ) => {
      //@ts-ignore
      state.objectives = sortObjectivesByDefault(action.payload)
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [getObjectives.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [getObjectives.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // get objective
    [getObjective.fulfilled.type]: (
      state,
      action: PayloadAction<Objective>,
    ) => {
      const steps = sortStepsByStatus(action.payload.steps, 'Active')
      //@ts-ignore
      state.objective = { ...action.payload, steps }
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [getObjective.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [getObjective.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // refresh objective
    [refreshObjective.fulfilled.type]: (
      state,
      action: PayloadAction<Objective>,
    ) => {
      //@ts-ignore
      state.objectives = state.objectives.map((objective: Objective) =>
        objective.id === action.payload.id ? action.payload : objective,
      )
      //@ts-ignore
      state.objective = { ...action.payload }
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [refreshObjective.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [refreshObjective.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // add objective
    [addObjective.fulfilled.type]: (
      state,
      action: PayloadAction<Objective>,
    ) => {
      const objectives = state.objectives
      //@ts-ignore
      objectives.push(action.payload)
      //@ts-ignore
      state.objectives = sortObjectivesByDefault(objectives)
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [addObjective.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [addObjective.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // delete objective
    [deleteObjective.fulfilled.type]: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.objectives = state.objectives.filter(
        (objective) => objective.id !== action.payload,
      )
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [deleteObjective.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [deleteObjective.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // update objective status
    [updateObjective.fulfilled.type]: (
      state,
      action: PayloadAction<Objective>,
    ) => {
      if (state.objectives.length > 0) {
        const updatedObjectives = state.objectives.map((objective) =>
          objective.id === action.payload.id ? action.payload : objective,
        )
        //@ts-ignore
        state.objectives = sortObjectivesByDefault(updatedObjectives)
      }
      if (state.objective) {
        //@ts-ignore
        state.objective = action.payload
      }
      state.objectiveLoading = false
      state.objectiveError = undefined
    },

    [updateObjective.pending.type]: (state) => {
      state.objectiveLoading = true
      state.objectiveError = undefined
    },

    [updateObjective.rejected.type]: (state, action) => {
      state.objectiveLoading = false
      state.objectiveError = action.payload
    },

    // save step
    [addStep.fulfilled.type]: (state, action: PayloadAction<Step>) => {
      state.objective!.steps.push(action.payload)
      state.stepLoading = false
      state.stepError = undefined
    },

    [addStep.pending.type]: (state) => {
      state.stepLoading = true
      state.stepError = undefined
    },

    [addStep.rejected.type]: (state, action) => {
      state.stepLoading = false
      state.stepError = action.payload
    },

    // update step
    [updateStep.fulfilled.type]: (state, action: PayloadAction<Step>) => {
      if (state.objectives.length > 0) {
        state.objectives.forEach((objective) => {
          if (objective.id === action.payload.objectiveId) {
            objective.steps.forEach((step) => {
              if (step.id === action.payload.id) step = action.payload
            })
          }
        })
      }

      if (state.objective) {
        const steps = state.objective.steps.map((step) =>
          step.id === action.payload.id ? action.payload : step,
        )
        state.objective.steps = sortStepsByStatus(steps, 'Active')
      }

      state.stepLoading = false
      state.stepError = undefined
    },

    [updateStep.pending.type]: (state) => {
      state.stepLoading = true
      state.stepError = undefined
    },

    [updateStep.rejected.type]: (state, action) => {
      state.stepLoading = false
      state.stepError = action.payload
    },

    // delete step
    [deleteStep.fulfilled.type]: (state, action: PayloadAction<number>) => {
      if (state.objective) {
        const steps = state.objective.steps.filter(
          (step) => step.id !== action.payload,
        )
        state.objective = { ...state.objective, steps }
      }
      state.stepLoading = false
      state.stepError = undefined
    },

    [deleteStep.pending.type]: (state) => {
      state.stepLoading = true
      state.stepError = undefined
    },

    [deleteStep.rejected.type]: (state, action) => {
      state.stepLoading = false
      state.stepError = action.payload
    },
  },

  // (builder) => {
  //     builder.addCase(getDevelopers.fulfilled, (state, action) => {
  //         state.developers = action.payload as IDeveloper[];
  //     })

  //     builder.addCase(addDeveloper.fulfilled, (state, action) => {
  //         state.developers.push(action.payload as IDeveloper);
  //     })

  //     builder.addCase(deleteDeveloper.fulfilled, (state, action) => {
  //         state.developers = state.developers.filter(developer => developer.id != action.payload)
  //     })
  // }
})

export const { setObjectives, setObjective, setObjectiveDetails } =
  objectiveSlice.actions
export default objectiveSlice.reducer
