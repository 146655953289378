import React, { useEffect, useState } from 'react'
import styles from './ObjectiveDetails.module.scss'
import { Objective } from '../../types/objective'
import PriorityTag from '../../../../components/PriorityTag/PriorityTag'
import StatusTag from '../../../../components/StatusTag/StatusTag'
import DateTag from '../../../../components/DateTag/DateTag'
import { Whiteboard } from '../../../Whiteboard/types/whiteboard'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../../redux/store'
import {
  deleteWhiteboard,
  setWhiteboard,
} from '../../../Whiteboard/store/whiteboard.slice'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ToDo } from '../../../Notes/ToDoes/types/toDo'
import { setToDo } from '../../../Notes/ToDoes/store/toDo.slice'

interface ObjectiveDetailsProps {
  objective: Objective
  setWhiteboard: (whiteboard?: Whiteboard) => void
  deleteWhiteboard: (id: string) => void
  openAddWhiteboard?: () => void
  openEditWhiteboardDrawer: () => void
  openDeleteWhiteboardDrawer: (whiteboardId?: string) => void
  setToDoDetails: (toDo?: ToDo) => void
  setToDo: (toDo?: ToDo) => void
  openAddToDo?: () => void
  openEditToDoDrawer: () => void
  openDeleteToDoDrawer: (toDo?: number) => void
}

const ObjectiveDetails = (props: ObjectiveDetailsProps) => {
  const {
    objective,
    openAddWhiteboard,
    openEditWhiteboardDrawer,
    openDeleteWhiteboardDrawer,
    setWhiteboard,
    setToDo,
    setToDoDetails,
    openAddToDo,
    openEditToDoDrawer,
    openDeleteToDoDrawer,
  } = props

  const [showPriority, setShowPriority] = useState<boolean>(true)
  const [showStatus, setShowStatus] = useState<boolean>(true)

  useEffect(() => {
    const screenWidth = window.innerWidth
    console.log('screenWidth', screenWidth)

    if (screenWidth < 600) {
      setShowPriority(false)
    }

    if (screenWidth < 400) {
      setShowStatus(false)
    }
  }, [])

  const navigator = useNavigate()

  // whiteboard
  const onOpenEditWhiteboardDrawer = (whiteboard: Whiteboard) => {
    setWhiteboard(whiteboard)
    openEditWhiteboardDrawer()
  }

  const onClickWhiteBoard = (whiteboard: Whiteboard) => {
    setWhiteboard(whiteboard)
    navigator(`/whiteboards/${whiteboard.id}`)
  }

  const onDeleteWhiteBoard = (whiteboardId: string) => {
    openDeleteWhiteboardDrawer(whiteboardId)
  }

  // toDo
  const onOpenEditToDoDrawer = (toDo: ToDo) => {
    setToDo(toDo)
    openEditToDoDrawer()
  }

  const onClickToDo = (toDo: ToDo) => {
    setToDoDetails(toDo)
  }

  const onDeleteToDo = (toDoId: number) => {
    openDeleteToDoDrawer(toDoId)
  }

  return (
    <div className={styles.root}>
      <div className={styles.priorityContainer}>
        <span className={styles.priority}>Priority: </span>
        <PriorityTag priority={objective.priority} />
      </div>
      <div className={styles.statusContainer}>
        <span className={styles.status}>Status: </span>
        <StatusTag status={objective.status} />
      </div>
      <div className={styles.dateContainer}>
        <span className={styles.date}>Deadline: </span>
        <DateTag date={objective.deadline} dateType="deadline" />
      </div>

      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: objective.description,
            }}
          />
        </div>
      </div>

      <div className={styles.whiteboardsContainer}>
        {objective.whiteboards?.length > 0 && (
          <span className={styles.whiteboardTitle}>Whiteboards</span>
        )}
        <div className={styles.whiteboards}>
          {objective.whiteboards?.length > 0 &&
            objective.whiteboards.map((whiteboard: Whiteboard) => (
              <div
                key={whiteboard.id}
                onClick={() => onClickWhiteBoard(whiteboard)}
                className={styles.whiteboard}
              >
                <div className={styles.name}>{whiteboard.name}</div>
                <div className={styles.whiteboardButtonContainer}>
                  <IconButton>
                    <MdModeEdit
                      onClick={() => onOpenEditWhiteboardDrawer(whiteboard)}
                      className={styles.icon}
                      size={25}
                    />
                  </IconButton>
                  <IconButton>
                    <MdDelete
                      onClick={() => onDeleteWhiteBoard(whiteboard.id)}
                      className={styles.icon}
                      size={25}
                    />
                  </IconButton>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.todosContainer}>
        {objective.toDoes?.length > 0 && (
          <span className={styles.todoTitle}>To Does</span>
        )}
        <div className={styles.todos}>
          {objective.toDoes?.length > 0 &&
            objective.toDoes.map((toDo: ToDo) => (
              <div
                key={toDo.id}
                onClick={() => onClickToDo(toDo)}
                className={styles.todo}
              >
                <div className={styles.name}>{toDo.name}</div>
                <div className={styles.rightPart}>
                  {showStatus && (
                    <div
                      className={
                        toDo.status === 'Active'
                          ? styles.todoActiveStatus
                          : toDo.status === 'Finished'
                          ? styles.todoFinishedStatus
                          : styles.todoPausedStatus
                      }
                    >
                      {toDo.status}
                    </div>
                  )}
                  {showPriority && (
                    <div
                      className={
                        toDo.priority === 'High'
                          ? styles.todoHighPriority
                          : toDo.priority === 'Medium'
                          ? styles.todoMediumPriority
                          : styles.todoLowPriority
                      }
                    >
                      {toDo.priority}
                    </div>
                  )}

                  <div className={styles.todoButtonContainer}>
                    <IconButton>
                      <MdModeEdit
                        onClick={() => onOpenEditToDoDrawer(toDo)}
                        className={styles.icon}
                        size={25}
                      />
                    </IconButton>
                    <IconButton>
                      <MdDelete
                        onClick={() => onDeleteToDo(toDo.id)}
                        className={styles.icon}
                        size={25}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button onClick={openAddToDo} variant="success">
          Add ToDo
        </Button>
        <Button onClick={openAddWhiteboard} variant="success">
          Add WhiteBoard
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  whiteboard: state.whiteboard.whiteboard,
})

export default connect(mapStateToProps, {
  setWhiteboard,
  deleteWhiteboard,
  setToDo,
})(ObjectiveDetails)
