import React, { useEffect, useState } from 'react'
import styles from './ToDo.module.scss'
import { ToDo as ToDoType } from '../../types/toDo'
import { format } from 'date-fns'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete, MdEdit } from 'react-icons/md'
import IconButton from '../../../../../components/IconButton/IconButton'

interface ToDoProps {
  toDo: ToDoType
  selectedNoteId: number
  editable?: boolean
  setSelectedNoteId: (noteId: number) => void
  onEdit: () => void
  onDetails: () => void
  onDelete: () => void
}

const ToDo = (props: ToDoProps) => {
  const [isActiveOptions, setIsActiveOptions] = useState<boolean>(false)

  const {
    toDo,
    selectedNoteId,
    editable = true,
    setSelectedNoteId,
    onEdit,
    onDetails,
    onDelete,
  } = props

  const onCloseOptionTable = () => {
    setIsActiveOptions(false)
    setSelectedNoteId(0)
  }

  useEffect(() => {
    document.addEventListener('click', onCloseOptionTable)

    return () => {
      document.removeEventListener('click', onCloseOptionTable)
    }
  }, [])

  useEffect(() => {
    selectedNoteId === toDo.id
      ? setIsActiveOptions(true)
      : isActiveOptions && setIsActiveOptions(false)
  }, [selectedNoteId])

  const onClickOptionsButton = () => {
    setSelectedNoteId(toDo.id)
  }

  const onEditNote = () => {
    setIsActiveOptions(false)
    setSelectedNoteId(0)
    onEdit()
  }

  const onDeleteNote = () => {
    setIsActiveOptions(false)
    setSelectedNoteId(0)
    onDelete()
  }

  return (
    <div className={styles.root} onClick={onDetails}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <span className={styles.noteName}>{toDo.name}</span>
        </div>
        {editable && (
          <div
            onClick={(e) => e.stopPropagation()}
            className={styles.btnContainer}
          >
            <IconButton onClick={() => onClickOptionsButton()}>
              <BsThreeDotsVertical className={styles.icon} size={24} />
            </IconButton>
            {isActiveOptions && (
              <div className={styles.btnTable}>
                <IconButton onClick={() => onEditNote()}>
                  <MdEdit size={20} />
                </IconButton>
                <IconButton onClick={() => onDeleteNote()}>
                  <MdDelete size={20} />
                </IconButton>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <span>{toDo.priority}</span>
        <div className={styles.dateContainer}>
          {toDo.endAt ? (
            <>
              <span className={styles.noteDate}>
                {`${format(new Date(toDo.startAt), 'dd/MM/yyyy')} - `}
              </span>
              <span className={styles.noteDate}>
                {`${format(new Date(toDo.endAt), 'dd/MM/yyyy')}`}
              </span>
            </>
          ) : (
            <span className={styles.noteDate}>
              {format(new Date(toDo.startAt), 'dd/MM/yyyy')}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ToDo
