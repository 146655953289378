import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { getEvents } from '../../store/event.slice'
import { Event } from '../../types/event'
import styles from './UpcomingEvents.module.scss'
import { format } from 'date-fns'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import EventDetails from '../EventDetails/EventDetails'

interface UpcomingEventsProps {
  events: Event[]
  upcomingEvents: Event[]
  getEvents: () => void
}

const UpcomingEvents = (props: UpcomingEventsProps) => {
  const { events, upcomingEvents, getEvents } = props
  useEffect(() => {
    getEvents()
  }, [])

  const [eventDetails, setEventDetails] = useState<Event | undefined>()

  return (
    <div className={styles.root}>
      {upcomingEvents?.length > 0 &&
        upcomingEvents.map((event) => (
          <div
            key={`upcoming-events-${event.id}`}
            className={styles.event}
            onClick={() => setEventDetails(event)}
          >
            <div className={styles.header}>
              <div className={styles.headerInfo}>
                <span className={styles.name}>{event.title}</span>
              </div>
            </div>
            <div className={styles.priorityContainer}>
              <div className={styles.priority}>{event.priority}</div>
            </div>

            <div className={styles.dateContainer}>
              <>
                <span className={styles.noteDate}>
                  {`${format(new Date(event.dates[0]), 'dd/MM/yyyy')} - `}
                </span>
                {event.dates?.length > 1}
                <span className={styles.noteDate}>
                  {`- ${format(
                    new Date(event.dates[event.dates.length - 1]),
                    'dd/MM/yyyy',
                  )}`}
                </span>
              </>
            </div>
          </div>
        ))}

      <RightDrawer
        title={`${eventDetails?.title}`}
        size="l"
        show={!!eventDetails}
        onHide={() => setEventDetails(undefined)}
      >
        {eventDetails && (
          <EventDetails
            event={eventDetails}
            editable={false}
            deletable={false}
            onUpdate={() => {}}
            onDelete={() => {}}
          />
        )}
      </RightDrawer>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  upcomingEvents: state.event.upcomingEvents,
})

export default connect(mapStateToProps, {
  getEvents,
})(UpcomingEvents)
