import React from 'react'
import styles from './ToDoDetails.module.scss'
import { ToDo } from '../../types/toDo'
import PriorityTag from '../../../../../components/PriorityTag/PriorityTag'
import DateTag from '../../../../../components/DateTag/DateTag'

interface ToDoDetailsProps {
  toDo: ToDo
}

const ToDoDetails = (props: ToDoDetailsProps) => {
  const { toDo } = props

  return (
    <div className={styles.root}>
      {toDo?.objective && <div>Objective: {toDo?.objective.name}</div>}
      <div className={styles.priorityContainer}>
        <span className={styles.priority}>Priority: </span>
        <PriorityTag priority={toDo.priority} />
      </div>
      <div className={styles.dateContainer}>
        <span className={styles.date}>Start Date: </span>
        <DateTag date={toDo.startAt} dateType="startedAt" />
      </div>
      <div className={styles.dateContainer}>
        <span className={styles.date}>End Date: </span>
        <DateTag date={toDo.endAt} dateType="endAt" />
      </div>
      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: toDo.description,
            }}
          />
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default ToDoDetails
