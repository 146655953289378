import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
} from 'react'
import { Button, Dropdown, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import {
  updateObjective,
  deleteObjective,
  deleteStep,
  getObjective,
  updateStep,
} from '../../store/objective.slice'
import styles from './Objective.module.scss'
import MyLoader from '../../../../components/MyLoader/MyLoader'
import { format } from 'date-fns'
import FormStep from '../../components/FormStep/FormStep'
import BottomLineLayout from '../../../../layouts/BottomLineLayout/BottomLineLayout'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Confirm from '../../../../components/Confirm/Confirm'
import { Objective as ObjectiveType, Step } from '../../types/objective'
import { RootState } from '../../../../redux/store'
import DatePikerPopUp from '../../../../components/DatePikerPopUp/DatePikerPopUp'

interface ObjectiveProps {
  objective: ObjectiveType
  getObjective: (objectiveId: number) => void
  deleteObjective: (objectiveId: number) => void
  updateObjective: (objective: ObjectiveType) => void
  deleteStep: (stepId: number) => void
  updateStep: (step: Step) => void
}

const Objective = ({
  objective,
  getObjective,
  deleteObjective,
  updateObjective,
  deleteStep,
  updateStep,
}: ObjectiveProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const nameRef = useRef<HTMLInputElement>()
  const [isNameEditMode, setIsNameEditMode] = useState<boolean>(false)
  const [isAddSteepModeActive, setIsAddSteepModeActive] =
    useState<boolean>(false)
  const [isAddUpdateSteepModeActive, setIsUpdateSteepModeActive] =
    useState<boolean>(false)
  const [isDeadlineEditMode, setIsDeadlineEditMode] = useState<boolean>(false)
  const [isStepDeadlineEditMode, setIsStepDeadlineEditMode] =
    useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const descriptionRef = useRef<HTMLInputElement>()
  const [isDescriptionEditMode, setIsDescriptionEditMode] =
    useState<boolean>(false)
  const [isActiveStepDeleteAlarm, setIsActiveStepDeleteAlarm] =
    useState<boolean>(false)
  const [isActiveObjectiveDeleteAlarm, setIsActiveObjectiveDeleteAlarm] =
    useState<boolean>(false)
  const [selectedStep, setSelectedStep] = useState<Step | undefined>(undefined)
  const [stepId, setStepId] = useState<number | null>(null)

  useEffect(() => {
    getObjective(parseInt(params.id!))
  }, [])

  // useEffect(() => {
  //   if (objective) {
  //     console.log('objective.steps', objective.steps)
  //   }
  // }, [objective])

  const onChangeStatus = async (status: string) => {
    const finishedAt = status === 'Finished' ? new Date().toISOString() : null

    const updatedObjective: ObjectiveType = {
      ...objective,
      status,
      finishedAt,
    }

    updateObjective(updatedObjective)
  }

  const onChangePriority = async (priority: string) => {
    const updatedObjective: ObjectiveType = {
      ...objective,
      priority,
    }

    updateObjective(updatedObjective)
  }

  const onChangeName = async (e: KeyboardEvent) => {
    if (e.key === 'Enter' && name) {
      const updatedObjective: ObjectiveType = {
        ...objective,
        name,
      }

      updateObjective(updatedObjective)
      setIsNameEditMode(false)
    }
  }

  const onActiveNameEditMode = async () => {
    setName(objective.name)
    await setIsNameEditMode(true)
    nameRef.current!.focus()
  }

  const onChangeDeadline = async (date: Date | undefined) => {
    const deadline = date ? date.toISOString() : undefined
    const updatedObjective: ObjectiveType = {
      ...objective,
      deadline,
    }

    updateObjective(updatedObjective)
    setIsDeadlineEditMode(false)
  }

  const onChangeDescription = async (e: KeyboardEvent) => {
    if (e.key === 'Enter' && description && !e.shiftKey) {
      const updatedObjective: ObjectiveType = {
        ...objective,
        description,
      }

      updateObjective(updatedObjective)
      setIsDescriptionEditMode(false)
    }
  }

  const onActiveDescriptionEditMode = async () => {
    setDescription(objective.description)
    await setIsDescriptionEditMode(true)
    descriptionRef.current!.focus()
  }

  const onActiveStepDeleteAlarm = (e: MouseEvent, stepId: number) => {
    e.stopPropagation()
    setIsActiveStepDeleteAlarm(true)
    setStepId(stepId)
  }

  const onCancelStepDelete = () => {
    setIsActiveStepDeleteAlarm(false)
    setStepId(null)
  }

  const onDeleteStep = async () => {
    stepId && (await deleteStep(stepId))
    setIsActiveStepDeleteAlarm(false)
    setStepId(null)
  }

  const onChangeStepStatus = async (step: Step, status: string) => {
    const newStep = {
      ...step,
      status,
    }

    updateStep(newStep)
  }

  const onStepEditMode = (step: Step) => {
    setSelectedStep(step)
    setIsUpdateSteepModeActive(true)
  }

  const onDeleteObjective = () => {
    deleteObjective(objective.id)
    navigate(-1)
  }

  const onOpenStepDeadline = (step: Step) => {
    setSelectedStep(step)
    setIsStepDeadlineEditMode(true)
  }

  const onChangeStepDeadline = (date: Date | undefined) => {
    if (selectedStep) {
      const deadline = date ? date.toISOString() : undefined
      const updatedStep = {
        ...selectedStep,
        deadline,
      }

      updateStep(updatedStep)
      setIsStepDeadlineEditMode(false)
    }
  }

  return (
    <BottomLineLayout
      onClickLeftButton={() => navigate(-1)}
      leftButtonName={'Back'}
      rightSecondButtonVariant={'danger'}
      rightSecondButtonName={'Delete'}
      onClickRightSecondButton={() => setIsActiveObjectiveDeleteAlarm(true)}
    >
      {objective ? (
        <div className={styles.root}>
          <div className={styles.objectiveInfo}>
            <div className={styles.objectiveInfoTop}>
              {isNameEditMode ? (
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Control
                    value={name}
                    //@ts-ignore
                    ref={nameRef}
                    onBlur={() => setIsNameEditMode(false)}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={onChangeName}
                    placeholder="Objective name..."
                  />
                </Form.Group>
              ) : (
                <span
                  onClick={onActiveNameEditMode}
                  className={styles.objectiveName}
                >
                  {objective.name}
                </span>
              )}
              <div className={styles.dropdowns}>
                <Dropdown
                  onSelect={(status) => status && onChangeStatus(status)}
                  className={styles.dropdown}
                >
                  <Dropdown.Toggle
                    className={styles.select}
                    id="dropdown-basic"
                  >
                    {objective.status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={'Active'}>Active</Dropdown.Item>
                    <Dropdown.Item eventKey={'Passive'}>Passive</Dropdown.Item>
                    <Dropdown.Item eventKey={'Finished'}>
                      Finished
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  onSelect={(priority) =>
                    priority && onChangePriority(priority)
                  }
                  className={styles.dropdown}
                >
                  <Dropdown.Toggle
                    className={styles.select}
                    id="dropdown-basic"
                  >
                    {objective.priority}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={'Low'}>Low</Dropdown.Item>
                    <Dropdown.Item eventKey={'Medium'}>Medium</Dropdown.Item>
                    <Dropdown.Item eventKey={'High'}>High</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div>
              <span className={styles.createdAt}>Created at:</span>
              <span>
                {format(new Date(objective.createdAt), 'dd MMMM yyyy')}
              </span>
            </div>
            <div className={styles.deadlineContainer}>
              <span className={styles.deadline}>DeadLine:</span>
              {objective.deadline ? (
                <span
                  onClick={() => setIsDeadlineEditMode(true)}
                  className={styles.deadlineDate}
                >
                  {format(new Date(objective.deadline), 'dd MMMM yyyy')}
                </span>
              ) : (
                <span
                  onClick={() => setIsDeadlineEditMode(true)}
                  className={styles.noDate}
                >
                  No deadline
                </span>
              )}
            </div>
            <div className={styles.finishedDate}>
              <span className={styles.finishedAt}>Finished at:</span>
              {objective?.status === 'Finished' && objective?.finishedAt ? (
                <span>
                  {format(new Date(objective?.finishedAt), 'dd MMMM yyyy')}
                </span>
              ) : (
                <span className={styles.noDate}>Not finished yet</span>
              )}
            </div>
            <div>
              <h6 className={styles.descriptionTitle}>Description:</h6>
              {isDescriptionEditMode ? (
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  //@ts-ignore
                  ref={descriptionRef}
                  style={{ height: '150px' }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onKeyPress={onChangeDescription}
                  onBlur={() => setIsDescriptionEditMode(false)}
                />
              ) : (
                <div
                  onClick={onActiveDescriptionEditMode}
                  className={styles.descriptionContainer}
                >
                  <pre className={styles.description}>
                    {objective.description}
                  </pre>
                </div>
              )}
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.stepsTop}>
            <div className={styles.stepsTop}>
              <div className={styles.stepsTopLeft}>
                <span className={styles.stepsTitle}>Steps</span>
              </div>
              <Button
                onClick={() => setIsAddSteepModeActive(true)}
                variant={'light'}
              >
                Add Step
              </Button>
            </div>
          </div>
          <div className={styles.steps}>
            {objective?.steps &&
              objective.steps.map((step) => (
                <div
                  key={step.id}
                  className={`${styles.step} ${
                    step.status === 'Active'
                      ? styles.activeStep
                      : step.status === 'Passive'
                      ? styles.pausedStep
                      : styles.finishedStep
                  }`}
                >
                  <div className={styles.stepTop}>
                    <span className={styles.stepName}>{step.name}</span>
                    <span className={styles.date}>{`${format(
                      new Date(step.createdAt),
                      'dd MMM yyyy',
                    )}`}</span>
                    <span
                      className={styles.deadline}
                      onClick={() => onOpenStepDeadline(step)}
                    >
                      {` - ${
                        step.finishedAt
                          ? format(new Date(step.finishedAt), 'dd MMM yyyy')
                          : step.deadline
                          ? format(new Date(step.deadline), 'dd MMM yyyy')
                          : 'no deadline'
                      }`}
                    </span>
                    <Dropdown
                      onSelect={(status) =>
                        status && onChangeStepStatus(step, status)
                      }
                      className={styles.dropdown}
                    >
                      <Dropdown.Toggle
                        className={styles.select}
                        id="dropdown-basic"
                      >
                        {step.status}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={'Active'}>
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={'Passive'}>
                          Passive
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={'Finished'}>
                          Finished
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className={styles.editButtonContainer}>
                      <IconButton>
                        <MdModeEdit
                          onClick={() => onStepEditMode(step)}
                          className={styles.icon}
                          size={25}
                        />
                      </IconButton>
                    </div>
                    <div className={styles.deleteButtonContainer}>
                      <IconButton>
                        <MdDelete
                          onClick={(e: MouseEvent) =>
                            onActiveStepDeleteAlarm(e, step.id)
                          }
                          className={styles.icon}
                          size={25}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div>
                    <pre>{step.description}</pre>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <MyLoader />
      )}
      {isAddSteepModeActive && objective ? (
        <FormStep
          title={'Add Step'}
          objectiveId={objective.id}
          onClickCancel={() => setIsAddSteepModeActive(false)}
        />
      ) : (
        <></>
      )}
      {isAddUpdateSteepModeActive && objective ? (
        <FormStep
          title={'Update Step'}
          step={selectedStep}
          objectiveId={objective.id}
          onClickCancel={() => setIsUpdateSteepModeActive(false)}
        />
      ) : (
        <></>
      )}
      {isActiveStepDeleteAlarm ? (
        <Confirm
          confirm="Are you really want to delete this step?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteStep}
          onCancel={onCancelStepDelete}
        />
      ) : (
        <></>
      )}
      {isActiveObjectiveDeleteAlarm ? (
        <Confirm
          confirm="Are you really want to delete this Objective?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteObjective}
          onCancel={() => setIsActiveObjectiveDeleteAlarm(false)}
        />
      ) : (
        <></>
      )}
      {isDeadlineEditMode ? (
        <DatePikerPopUp
          initialDate={
            objective.deadline
              ? new Date(objective.deadline as string)
              : undefined
          }
          onClose={() => setIsDeadlineEditMode(false)}
          onUpdate={onChangeDeadline}
        />
      ) : (
        <></>
      )}
      {isStepDeadlineEditMode ? (
        <DatePikerPopUp
          initialDate={
            selectedStep?.deadline
              ? new Date(selectedStep.deadline as string)
              : undefined
          }
          onClose={() => setIsStepDeadlineEditMode(false)}
          onUpdate={onChangeStepDeadline}
        />
      ) : (
        <></>
      )}
    </BottomLineLayout>
  )
}

const mapStateToProps = (state: RootState) => ({
  objective: state.objective.objective,
})

export default connect(mapStateToProps, {
  getObjective,
  deleteObjective,
  updateObjective,
  deleteStep,
  updateStep,
  //@ts-ignore
})(Objective)
