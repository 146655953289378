import React, { useEffect, useState } from 'react'
import { getToDoes } from '../../store/toDo.slice'
import { RootState } from '../../../../../redux/store'
import { connect } from 'react-redux'
import { ToDo as ToDoType } from '../../types/toDo'
import styles from './ActiveToDoesList.module.scss'
import RightDrawer from '../../../../../components/RightDrawer/RightDrawer'
import ToDoDetails from '../ToDoDetails/ToDoDetails'
import { format } from 'date-fns'

interface ActiveToDoesListProps {
  toDoes: ToDoType[]
  getToDoes: () => void
}

const ActiveToDoesList = (props: ActiveToDoesListProps) => {
  const { toDoes, getToDoes } = props
  const [activeToDoes, setActiveToDoes] = useState<ToDoType[]>([])
  const [toDoDetails, setToDoDetails] = useState<ToDoType | undefined>()

  useEffect(() => {
    getToDoes()
    // const screenWidth = window.innerWidth
    // screenWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (toDoes?.length > 0) {
      setActiveToDoes(toDoes.filter((toDo) => toDo.status === 'Active'))
    }
  }, [toDoes])
  return (
    <div className={styles.root}>
      {activeToDoes?.length > 0 &&
        activeToDoes.map((toDo) => (
          <div
            key={`active-todos-${toDo.id}`}
            className={styles.toDo}
            onClick={() => setToDoDetails(toDo)}
          >
            <div className={styles.header}>
              <div className={styles.headerInfo}>
                <span className={styles.name}>{toDo.name}</span>
              </div>
            </div>
            <div className={styles.priorityContainer}>
              <div className={styles.priority}>{toDo.priority}</div>
            </div>

            <div className={styles.dateContainer}>
              {toDo.endAt ? (
                <>
                  <span className={styles.noteDate}>
                    {`${format(new Date(toDo.startAt), 'dd/MM/yyyy')} - `}
                  </span>
                  <span className={styles.noteDate}>
                    {`${format(new Date(toDo.endAt), 'dd/MM/yyyy')}`}
                  </span>
                </>
              ) : (
                <span className={styles.noteDate}>
                  {format(new Date(toDo.startAt), 'dd/MM/yyyy')}
                </span>
              )}
            </div>
          </div>
        ))}

      <RightDrawer
        title={`${toDoDetails?.name}`}
        size="l"
        show={!!toDoDetails}
        onHide={() => setToDoDetails(undefined)}
      >
        {toDoDetails && <ToDoDetails toDo={toDoDetails} />}
      </RightDrawer>
    </div>
  )
}

// export default ActiveToDoesList

const mapStateToProps = (state: RootState) => ({
  toDoes: state.toDo.toDoes,
})

export default connect(mapStateToProps, {
  getToDoes,
})(ActiveToDoesList)
